import moment from "moment";

export const getTimeFromParameter = (target) => {
    const timeRegex = /^([0-9]{1,2}):([0-9]{1,2}):([0-9]{1,2})$/;
    const timeParts = target.match(timeRegex);

    if (timeParts === null) {
        throw new Error(`Invalid time string: ${target}`);
    }

    const hours = parseInt(timeParts[1], 10);
    const minutes = parseInt(timeParts[2], 10);
    const seconds = parseInt(timeParts[3], 10);

    if (hours < 0 || hours > 23) {
        throw new Error(`Invalid hours: ${hours}`);
    }

    if (minutes < 0 || minutes > 59) {
        throw new Error(`Invalid minutes: ${minutes}`);
    }

    if (seconds < 0 || seconds > 59) {
        throw new Error(`Invalid seconds: ${seconds}`);
    }

    return {
        hours,
        minutes,
        seconds,
    };


}

export const queryParametersTime = (currentTime) => {
    let result = null;
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let target = params.get('target');

    let parseResult = null;
    try {
        parseResult = getTimeFromParameter(target);
    } catch (error) {
    }

    if (parseResult) {
        let currentTargetTime = moment(currentTime).set(parseResult);
        if (currentTargetTime.isBefore(currentTime)) {
            // if the current hour has already passed, get the next relevant hour for the next day
            currentTargetTime = moment(currentTargetTime).add(1, 'days');
        }
        result = currentTargetTime;
    }

    return result;
}
