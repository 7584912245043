import Chart from "react-apexcharts";
import useCheckMobileScreen from "../../utils/useCheckMobileScreen";

const generateOptions = ({secondsLeftCountdown, totalProgressPercentage, hoursLeftCountdown, minutesLeftCountdown, isSmallScreen}) => {
    const countdownLabel = [hoursLeftCountdown, minutesLeftCountdown, secondsLeftCountdown].map(item => `${item}`.padStart(2, "0")).join(':');

    const resultOptions = {
        animations: {
            enabled: false,
        },
        plotOptions: {
            radialBar: {
                track: {
                    background: "#001E3CFF",
                },
                dataLabels: {
                    name: {
                        fontSize: '22px',
                    },
                    value: {
                        fontSize: '16px',
                        formatter: function (value) {
                            let result = "";
                            // console.log(`val=${value} and total=${totalProgressPercentage} state=${value === totalProgressPercentage}`);
                            if (value === `${totalProgressPercentage}`) {
                                result = `${value}%`
                            }
                            return result;

                        }
                    },
                    total: {
                        show: true,
                        // label: `${hoursLeft}:${minutesLeft}:${secondsLeft}`,
                        label: countdownLabel,
                        fontSize: "30px",
                        color: '#10baf8',
                        formatter: function (w) {
                            // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                            return ``
                        }
                    }
                }
            }
        },
        labels: ['Total Progress', 'Hours', 'Minutes', 'Seconds'],

    };

    if (isSmallScreen) {
        resultOptions['grid'] = {
            padding: {
                top: 0,
                bottom: 10
            }
        };
    } else {
        resultOptions['grid'] = {
            padding: {
                top: 0,
                bottom: 0
            }
        };
    }

    return resultOptions;

};

function RadialClock({totalProgressPercentage, hoursPercentage, minutesPercentage, secondsPercentage, secondsLeftCountdown, hoursLeftCountdown, minutesLeftCountdown}) {
    const series = [totalProgressPercentage, hoursPercentage, minutesPercentage, secondsPercentage];
    const isSmallScreen = useCheckMobileScreen();

    const options = generateOptions({secondsLeftCountdown, totalProgressPercentage, hoursLeftCountdown, minutesLeftCountdown, isSmallScreen});

    let heightCalculation = 700;
    if (isSmallScreen) {
        heightCalculation = 400;
    }

    return (
        <div className="Chart">
            <Chart options={options} series={series} type="radialBar" height={heightCalculation}/>
        </div>
    )
}

export default RadialClock;
