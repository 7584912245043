import {Box} from "@mui/material";

const getImageData = (morningStatusEnabled) => {
    let imgSource = "/images/sun.png";
    let altImage = "Sun";
    if (!morningStatusEnabled) {
        imgSource = "/images/moon.png";
        altImage = "Moon";
    }

    return [imgSource, altImage];
}

const getFormattedCurrentTimeForTopBar = (currentTime) => `${currentTime.format('HH:mm:ss')}`;
const getFormattedEndTimeForTopBar = (endTime) => `${endTime.format('HH:mm:ss')}`;

const Topbar = (props) => {


    // console.log(`Current=${props.currentTime} End=${props.endTime}`);
    const [imgSource, altImage] = getImageData(props.morningStatusEnabled);
    const formattedCurrentTime = getFormattedCurrentTimeForTopBar(props.currentTime);
    const formattedEndTime = getFormattedEndTimeForTopBar(props.endTime);

    return (
        <>
            <Box className="TopBox">
            <div className="TopTitle">{formattedCurrentTime}</div>
            <div className="TopTitle">{formattedEndTime}</div>
            <img className="morningStatusImage" src={imgSource} alt={altImage}/></Box>
        </>
    )
};

export default Topbar;
