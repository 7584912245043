import './App.css';
import {Container} from "@mui/material";
import {useEffect, useState} from "react";
import moment from 'moment';
import {getEndTime, getGaugesPercentages, getTimes} from "./utils/timeData";
import {getFirebaseAnalytics} from './firebase';
import RadialClockBar from "./layouts/radialBar/RadialClockBar";
import Topbar from "./layouts/topbar/Topbar";
import Footer from "./layouts/footer/Footer";
import {queryParametersTime} from "./utils/queryParametersTime";


const settingTimes = (setCurrentTime, setEndTime, setStartTime, endTime) => {
    const currentMomentTime = moment();
    setCurrentTime(moment())
    if (currentMomentTime.isAfter(endTime)) {
        // console.log('setting new end time');
        const newEndTime = getEndTime(moment());
        setEndTime(newEndTime);
        setStartTime(moment(newEndTime).subtract(24,'hours'));
    }

}

const attemptToGetEndTimeFromParams = (currentTime) => {
    return queryParametersTime(currentTime);
}

const currentTime = moment();
let initialEndTime = getEndTime(currentTime);

const endTimeFromParams = attemptToGetEndTimeFromParams(currentTime);
if (endTimeFromParams) {
    initialEndTime = endTimeFromParams;
}

function App() {
    getFirebaseAnalytics();

    // End time is the target - for tests it is 5 minutes from now, but on prod - it should be the target hour
    const [currentTime, setCurrentTime] = useState(moment());

    const [endTime, setEndTime] = useState(initialEndTime);
    // Using this line for tests
    // const [endTime, setEndTime] = useState(moment().add(5, 'seconds'));

    const [startTime, setStartTime] = useState(moment(endTime).subtract(24,'hours'));

    useEffect(() => {
        const interval = setInterval(() => {
            settingTimes(setCurrentTime, setEndTime, setStartTime, endTime);

        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [endTime]);

    const {
        timeLeft,
        totalTime,
    } = getTimes(startTime, endTime, currentTime);

    const {
        totalProgress,
        hoursProgress,
        minutesProgress,
        secondsProgress,
        hoursLeft,
        minutesLeft,
        secondsLeft,
        morningStatusEnabled,
        hoursLeftCountdown,
        minutesLeftCountdown,
        secondsLeftCountdown,
    } = getGaugesPercentages(timeLeft, totalTime);

    const props = {
        totalProgressPercentage: totalProgress,
        hoursPercentage: hoursProgress,
        minutesPercentage: minutesProgress,
        secondsPercentage: secondsProgress,
        hoursLeftCountdown: hoursLeftCountdown,
        minutesLeftCountdown: minutesLeftCountdown,
        secondsLeftCountdown: secondsLeftCountdown,
        hoursLeft: hoursLeft,
        minutesLeft: minutesLeft,
        secondsLeft: secondsLeft,
        currentTime,
        endTime,
        morningStatusEnabled,
    }

    useEffect(() => {
        const faviconUpdate = () => {
            //grab favicon element by ID
            const favicon = document.getElementById("favicon");

            if (favicon) {
                if (morningStatusEnabled) {
                    favicon.href = "/images/sun-favicon.ico";
                } else {
                    favicon.href = "/images/moon-favicon.ico";
                }
            }
        };

        faviconUpdate();

    }, [morningStatusEnabled]);

    // console.log(JSON.stringify(props))
    return (
        <div className="App">
            <Container>
                <Topbar {...props}/>
            </Container>
           <Container>
               <RadialClockBar {...props}/>
           </Container>
            <Container>
                <Footer {...props}/>
            </Container>
        </div>
    );
}

export default App;
