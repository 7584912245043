import {Box} from "@mui/material";
import RadialClock from "../../components/RadialClock/RadialClock";

const RadialClockBar = (props) => {
    return (
        <Box className="RadialBox">
            <RadialClock
                {...props}
            />
        </Box>
    )

}

export default RadialClockBar;
