import moment from "moment";

// After testing this is the maximum amount of percentages that are still displayed as not filled in the radialBar chart
// Started from 2 minutes and 1 second that yielded 99.8599537 and continued from there using binary search to 100. stopped at the current value
export const MAX_NOT_FILLED_TOTAL_PROGRESS = 99.86104362;

export const getTimes = (startTime, endTime, currentTime) => {
    let totalTime = moment.duration(endTime.diff(startTime));
    let progress = moment.duration(currentTime.diff(startTime));
    let timeLeft = moment.duration(endTime.diff(currentTime));
    let percentage = (progress.asMilliseconds() / totalTime.asMilliseconds()) * 100;
    // console.log(`totalTime=${totalTime.asSeconds()}`)
    // console.log(`progress=${progress.asSeconds()}`)
    // console.log(`timeLeft=${timeLeft.asSeconds()}`)
    // console.log(`percentage=${percentage}%`)

    return {
        totalTime,
        progress,
        timeLeft,
        percentage,
    }
}

const getIsNightStatus = (totalProgress, timeLeft) => {
    let nightStatus = false;

    if (totalProgress < 100 && timeLeft.asHours() < 12) {
        nightStatus = true;
    }


    return nightStatus;
}

export const getGaugesPercentages = (timeLeft, totalTime) => {
    const totalProgressCheck = (totalTime - timeLeft) / totalTime * 100;


    let hoursProgress = 100;
    let minutesProgress = 100;
    let secondsProgress = 100;
    let howMuchTimeHours = 0;
    let howMuchTimeMinutes = 0;
    let howMuchTimeSeconds = 0;

    let hoursLeft = 0;
    let minutesLeft = 0;
    let secondsLeft = 0;
    let hoursLeftCountdown = 0;
    let minutesLeftCountdown = 0;
    let secondsLeftCountdown = 0;

    const totalTimeHours = Math.ceil(totalTime.asHours()) % 24 || 24;
    const totalTimeMinutes = Math.ceil(totalTime.asMinutes()) % 60 || 60;
    const totalTimeSeconds = Math.ceil(totalTime.asSeconds()) % 60 || 60;

    let morningStatusEnabled = true;

    const nightStatus = getIsNightStatus(totalProgressCheck, timeLeft);

    let totalProgress = totalProgressCheck;


    if (nightStatus) {
        // const howMuchTimeHours = (Math.ceil(totalTime.asHours()) - Math.ceil(timeLeft.asHours())) % 24 || totalTimeHours;
        howMuchTimeHours = (Math.ceil(totalTime.asHours()) - Math.ceil(timeLeft.asHours())) % 24;
        hoursProgress = howMuchTimeHours / totalTimeHours * 100;
        hoursLeft = totalTimeHours - howMuchTimeHours;
        hoursLeftCountdown = hoursLeft - 1;

        // const howMuchTimeMinutes = (Math.ceil(totalTime.asMinutes()) - Math.ceil(timeLeft.asMinutes())) % 60 || totalTimeMinutes;
        howMuchTimeMinutes = (Math.ceil(totalTime.asMinutes()) - Math.ceil(timeLeft.asMinutes())) % 60;
        minutesProgress = howMuchTimeMinutes / totalTimeMinutes * 100;
        minutesLeft = totalTimeMinutes - howMuchTimeMinutes;
        minutesLeftCountdown = minutesLeft - 1;

        // const howMuchTimeSeconds = (Math.ceil(totalTime.asSeconds()) - Math.ceil(timeLeft.asSeconds())) % 60 || totalTimeSeconds;
        howMuchTimeSeconds = (Math.ceil(totalTime.asSeconds()) - Math.ceil(timeLeft.asSeconds())) % 60;
        secondsProgress = howMuchTimeSeconds / totalTimeSeconds * 100;
        secondsLeft = totalTimeSeconds - howMuchTimeSeconds;
        secondsLeftCountdown = secondsLeft;

        if (secondsLeftCountdown === 60) {
            secondsLeftCountdown = 0;
            minutesLeftCountdown += 1;
        }

        if (minutesLeftCountdown === 60) {
           minutesLeftCountdown = 0;
           hoursLeftCountdown += 1;
        }

        morningStatusEnabled = false;


        if (totalProgress > MAX_NOT_FILLED_TOTAL_PROGRESS) {
            totalProgress = MAX_NOT_FILLED_TOTAL_PROGRESS;
        }

    } else {
        totalProgress = 100;
    }


    return {
        totalProgress,
        hoursProgress,
        minutesProgress,
        secondsProgress,
        hoursLeft,
        minutesLeft,
        secondsLeft,
        morningStatusEnabled,
        hoursLeftCountdown,
        minutesLeftCountdown,
        secondsLeftCountdown,
    }

}

const hoursByDay = {
    Sunday: {
        hours: '06',
        minutes: '40'
    },
    Monday: {
        hours: '06',
        minutes: '40'
    },
    Tuesday: {
        hours: '06',
        minutes: '40'
    },
    Wednesday: {
        hours: '06',
        minutes: '40'
    },
    Thursday: {
        hours: '06',
        minutes: '40'
    },
    Friday: {
        hours: '06',
        minutes: '40'
    },
    Saturday: {
        hours: '08',
        minutes: '30'
    },
};

export const getEndTime = (currentMoment, shouldCheckNextDay = true) => {
    const day = currentMoment.format('dddd');
    const currentHour = hoursByDay[day];
    const currentMomentHour = moment(currentMoment).set({...currentHour, seconds: 0});
    if (shouldCheckNextDay && currentMomentHour.isBefore(currentMoment)) {
        // if the current hour has already passed, get the next relevant hour for the next day
        return getEndTime(moment(currentMoment).day(day).add(1, 'days'), false);
    }
    return currentMomentHour;

}
