import {Box} from "@mui/material";

const Footer = (props) => {


    return (
        <>
            <Box className="Footer">
            </Box>
        </>
    )
};

export default Footer;
